<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5 class="mb-4">
								<strong>Catálogo de unidades de medida</strong>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddRecord">Añadir</a-button>
							<a-button class="btn btn-success ml5" icon="reload" @click="onReload" />
						</div>
					</div>
					<a-table :columns="columns" :dataSource="measurementUnitsList" rowKey="id">
						<span slot="active" slot-scope="record">
							<a-tag color="green" v-if="record.active">Activo</a-tag>
							<a-tag color="red" v-else>Inactivo</a-tag>
						</span>
						<span slot="action" slot-scope="record">
							<a-button class="ml5" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="onToggleRecord(record.id, record.active)" />
						</span>
					</a-table>
				</div>
			</div>
		</a-spin>
		<insertOrUpdateMeasurementUnits :visible="modal.visible" :title="modal.title" @success="onCloseModal" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import insertOrUpdateMeasurementUnits from '@/components/insertOrUpdateMeasurementUnits'

export default {
	name: 'measurementUnitsView',
	components: {
		insertOrUpdateMeasurementUnits,
	},
	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: 'Nombre',
					dataIndex: 'unit_name',
					align: 'center',
				},
				{
					title: 'Clave SAT',
					dataIndex: 'sat_key',
				},
				{
					title: 'Estatus',
					scopedSlots: { customRender: 'active' },
					align: 'center',
				},
			],
			modal: {
				visible: false,
				title: '',
			},
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('measurementUnits', ['measurementUnitsList']),
		spinnerLoaderLabel() {
			return this.$store.state.measurementUnits.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.measurementUnits.spinnerLoader
		},
	},
	mounted() {
		if (this.measurementUnitsList.length == 0) {
			this.initModule()
		}
	},
	methods: {
		initModule() {
			this.$store.dispatch('measurementUnits/GET')
		},
		onReload() {
			this.initModule()
		},
		onAddRecord() {
			this.modal = {
				visible: true,
				title: 'Añadir registro de unidades de medida',
			}
		},
		onToggleRecord(id, status) {
			Swal.fire({
				title: 'Atención',
				text: `¿Desea ${status ? 'deshabilitar' : 'habilitar'} el registro?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('measurementUnits/TOGGLE', id)
				}
			})
		},
		onCloseModal() {
			this.modal.visible = false
		},
	},
}
</script>