<template>
	<div>
		<a-modal :visible="visible" :title="title" :closable="false" width="45%">
			<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
				<div class="row">
					<div class="col-md-12">
						<label>Unidad de medida</label>
						<a-input-search v-model="searchMeasurementUnit" placeholder="Ingresa texto para buscar en el SAT" enter-button @search="onSearch" />
					</div>
					<div class="col-md-12" v-if="searchMeasurementUnitsList.total_results">
						<a-table :columns="columns" :dataSource="searchMeasurementUnitsList.data" :row-selection="rowSelection" rowKey="key"></a-table>
					</div>
					<div class="col-md-12" v-if="searchMeasurementUnit && searchMeasurementUnitsList.total_results == 0">
						<p class="text-center pt20">- Sin resultados de la búsqueda -</p>
					</div>
				</div>
			</a-spin>
			<template slot="footer">
				<a-button key="back" @click="onCancel"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onSaveRecord"> Guardar </a-button>
			</template>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

export default {
	name: 'insertOrUpdateMeasurementUnitsComponent',
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
	},
	computed: {
		...mapGetters('measurementUnits', ['searchMeasurementUnitsList']),
		spinnerLoaderLabel() {
			return this.$store.state.measurementUnits.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.measurementUnits.spinnerLoader
		},
	},
	data() {
		return {
			selectedMeasurementUnit: {},
			searchMeasurementUnit: '',
			columns: [
				{
					title: 'Clave SAT',
					dataIndex: 'key',
					align: 'center',
				},
				{
					title: 'Nombre',
					dataIndex: 'description',
				},
			],
			rowSelection: {
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedMeasurementUnit = {
						unit_name: selectedRows[0].description,
						sat_key: selectedRows[0].key,
					}
				},
				type: 'radio',
			},
		}
	},
	methods: {
		onSearch() {
			if (this.searchMeasurementUnit != '') {
				this.$store.dispatch('measurementUnits/SEARCH_UNITS', this.searchMeasurementUnit)
			} else {
				Swal.fire({
					title: 'Unidades de medida',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			}
		},
		onCancel() {
			this.$emit('success')
		},
		onSaveRecord() {
			if (utilities.objectValidate(this.selectedMeasurementUnit, 'sat_key', false)) {
				this.$store.dispatch('measurementUnits/CREATE', this.selectedMeasurementUnit).then(() => {
					this.$emit('success')
				})
			} else {
				Swal.fire({
					title: 'Unidades de medida',
					text: 'Debes seleccionar un registro para continuar',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			}
		},
	},
	watch: {
		visible(isVisible) {
			if (!isVisible) {
				this.$store.commit('measurementUnits/SET_STATE', {
					searchMeasurementUnitsList: [],
				})
				this.searchMeasurementUnit = ''
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>